import React, { useState } from "react";

import BlogListElementOptions from "../BlogListElementOptions/BlogListElementOptions";

import styles from "./BlogListElement.module.scss";

const convertISODateToString = (ISODate) => {
  const date = new Date(ISODate).toString();
  const dateArray = date.split(" ").slice(1, 4);
  const dateString = `${dateArray[0]} ${dateArray[1]}, ${dateArray[2]}`;
  return dateString;
};

const BlogListElement = ({ blog, blogs, setBlogs }) => {
  const [hover, setHover] = useState(false);

  const dateString = convertISODateToString(blog.createdDate);
  return (
    <div
      className={styles.blogListElementContainer}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {hover && (
        <BlogListElementOptions blog={blog} blogs={blogs} setBlogs={setBlogs} />
      )}
      <div className={styles.blogTextContainer}>
        <h2 className={styles.blogTitle}>{blog.title}</h2>
        <p className={styles.blogPreview}>{blog.preview}</p>
        <div className={styles.blogAttrContainer}>
          {blog.tags.map((tag, i) => (
            <span key={i} className={styles.blogTag}>
              {tag}
            </span>
          ))}
          <span className={styles.blogAttrSpacing}>•</span>
          <span className={styles.blogDate}>{dateString}</span>
        </div>
      </div>
      <img
        className={styles.thumbnail}
        src={blog.thumbnail}
        alt="blog-thumbnail"
      />
    </div>
  );
};

export default BlogListElement;
