const filtersArray = [
  "All",
  "Python",
  "C++",
  "JavaScript",
  "Golang",
  "React",
  "React Native",
  "Three",
  "Node",
  "Express",
  "MySQL",
  "PostgreSQL",
  "MongoDB",
  "Heroku",
  "Firebase",
  "Docker",
];

export default filtersArray;
