import React from "react";

import Markdown from "./Markdown/Markdown";

import defaultImage from "../../../assets/default.png";
import "./BlogViewer.scss";

const convertISODateToString = (ISODate) => {
  const date = new Date(ISODate).toString();
  const dateArray = date.split(" ").slice(1, 4);
  const dateString = `${dateArray[0]} ${dateArray[1]}, ${dateArray[2]}`;
  return dateString;
};

const exampleMarkdown = `A paragraph with *emphasis* and **strong importance**.

> A block quote with ~strikethrough~ and a URL: https://reactjs.org.

* Lists
* [ ] todo
* [x] done

A table:

| a | b |
| - | - |
`;

const BlogViewer = ({ blog }) => {
  return (
    <div className="blog-post-container">
      <h1 className="blog-post-title">{blog.title || "Title"}</h1>
      <h3 className="blog-post-subtitle">{blog.subtitle || "Subtitle"}</h3>
      <img
        className="blog-post-thumbnail"
        src={blog.thumbnail || defaultImage}
        alt="blog thumbnail"
        style={blog.thumbnail ? {} : { height: 300, objectFit: "cover" }}
      />
      <div className="blog-post-markdown-container">
        <Markdown markdown={blog.markdown || exampleMarkdown} />
      </div>
      <hr />
      <div className="blog-post-attr-container">
        <div className="blog-post-tag-container">
          {blog.tags ? (
            blog.tags?.map((tag, i) => (
              <span key={i} className="blog-post-tag">
                {tag}
              </span>
            ))
          ) : (
            <span className="blog-post-tag">Tag</span>
          )}
        </div>
        <p className="blog-post-date">
          {blog.createdDate
            ? convertISODateToString(blog.createdDate)
            : convertISODateToString(Date.now())}
        </p>
      </div>
    </div>
  );
};

export default BlogViewer;
