import React from "react";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";

import BlogViewer from "./BlogViewer/BlogViewer";
import BlogForm from "./BlogForm/BlogForm";

import styles from "./BlogEditor.module.scss";

const BlogEditor = ({ blog, setBlog, isLoading }) => {
  if (!blog || isLoading) {
    return <span className="temporaryLoader" />;
  }

  return (
    <ScrollSync>
      <div className={styles.blogEditorContainer}>
        <ScrollSyncPane>
          <div className={styles.overflowContainer}>
            <BlogForm blog={blog} setBlog={setBlog} />
          </div>
        </ScrollSyncPane>
        <ScrollSyncPane>
          <div className={styles.overflowContainer}>
            <BlogViewer blog={blog} />
          </div>
        </ScrollSyncPane>
      </div>
    </ScrollSync>
  );
};

export default BlogEditor;
