import React from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEye, faEdit } from "@fortawesome/free-solid-svg-icons";

import styles from "./BlogListElementOptions.module.scss";

const BlogListElementOptions = ({ blog, blogs, setBlogs }) => {
  const navigate = useNavigate();
  const handleEdit = () => navigate("./" + blog.blogId);

  const handleDelete = async () => {
    if (window.confirm(`Are you sure you want to delete "${blog.title}"?`)) {
      const link = process.env.REACT_APP_API_ENDPOINT + "/blogs/" + blog.blogId;
      const config = {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      };
      const response = await Axios.delete(link, config);
      if (response.status === 204) {
        setBlogs(blogs.filter((item) => item.blogId !== blog.blogId));
      } else {
        console.error(response);
      }
    }
  };

  return (
    <div className={styles.blogListElementOptionsContainer}>
      {/* View */}
      <a
        href={`${process.env.REACT_APP_CLIENT}/blog/${blog.blogId}`}
        target="_blank"
        rel="noreferrer"
      >
        <div className={styles.option}>
          <FontAwesomeIcon icon={faEye} className={styles.icon} />
        </div>
      </a>

      {/* Edit */}
      <div className={styles.option} onClick={handleEdit}>
        <FontAwesomeIcon icon={faEdit} className={styles.icon} />
      </div>

      {/* Delete */}
      <div className={styles.option} onClick={handleDelete}>
        <FontAwesomeIcon
          icon={faTrash}
          className={styles.icon}
          style={{ color: "#ee4035" }}
        />
      </div>
    </div>
  );
};

export default BlogListElementOptions;
