import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";
import { Store } from "react-notifications-component";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faFloppyDisk,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { BlogEditor } from "../../components";

import styles from "./CreateBlog.module.scss";

const CreateBlog = () => {
  const [blog, setBlog] = useState(
    JSON.parse(localStorage.getItem("blog")) || {}
  );
  const defaultValueBlogId = useRef(blog.blogId);
  const [cursor, setCursor] = useState("default");

  useEffect(() => {
    if (Object.keys(blog).length !== 0) {
      localStorage.setItem("blog", JSON.stringify(blog));
    }
  }, [blog]);

  const handleBack = () => {
    if (
      window.confirm(
        "Are you sure you want to leave? (Unsaved changes will be lost)"
      )
    ) {
      window.history.back();
    }
  };

  const handleReset = () => {
    setBlog({});
    localStorage.removeItem("blog");
    document.getElementById("blogId").textContent = "";
  };

  const handleSave = async () => {
    if (
      !blog ||
      !blog.blogId ||
      !blog.title ||
      !blog.subtitle ||
      !blog.thumbnail ||
      !blog.markdown ||
      !blog.tags ||
      blog.tags.length === 0
    ) {
      Store.addNotification({
        title: "Failed",
        message: "Blog creation failed (Missing Parameters)",
        type: "danger",
        insert: "top",
        container: "top-left",
        animationIn: ["animate__animated animate__fadeIn"],
        animationOut: ["animate__animated animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
      return;
    }
    try {
      setCursor("wait");
      const link = process.env.REACT_APP_API_ENDPOINT + "/blogs";
      const config = {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      };
      const response = await Axios.post(link, blog, config);
      if (response.status === 201) {
        Store.addNotification({
          title: "Success",
          message: "Blog created successfully!",
          type: "success",
          insert: "top",
          container: "top-left",
          animationIn: ["animate__animated animate__fadeIn"],
          animationOut: ["animate__animated animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
        localStorage.removeItem("blog");
        return;
      }
    } catch (error) {
      Store.addNotification({
        title: "Failed",
        message: "Blog creation failed! (Network Error)",
        type: "danger",
        insert: "top",
        container: "top-left",
        animationIn: ["animate__animated animate__fadeIn"],
        animationOut: ["animate__animated animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    } finally {
      setCursor("default");
    }
  };

  function useKey(key, cb) {
    const callback = useRef(cb);

    useEffect(() => {
      callback.current = cb;
    });

    useEffect(() => {
      function handle(event) {
        if (event.code === key) {
          event.preventDefault();
          callback.current(event);
        } else if (key === "ctrls" && event.key === "s" && event.ctrlKey) {
          event.preventDefault();
          callback.current(event);
        } else if (key === "ctrls" && event.key === "s" && event.metaKey) {
          event.preventDefault();
          callback.current(event);
        }
      }

      document.addEventListener("keydown", handle);
      return () => document.removeEventListener("keydown", handle);
    }, [key]);
  }

  useKey("ctrls", handleSave);

  return (
    <div className={styles.createBlogContainer} style={{ cursor: cursor }}>
      <div className={styles.editBlogMenu}>
        <div className={styles.backContainer} onClick={handleBack}>
          <FontAwesomeIcon icon={faArrowLeft} className={styles.backIcon} />
          <span className={styles.backText}>Back to Blogs</span>
        </div>
        <h1 style={{ userSelect: "none" }}>&nbsp;</h1>
        <div className={styles.createOptionsContainer}>
          <div className={styles.saveContainer} onClick={handleReset}>
            <span className={styles.saveText}>Reset</span>
            <FontAwesomeIcon icon={faTrash} className={styles.saveIcon} />
          </div>
          <div className={styles.saveContainer} onClick={handleSave}>
            <span className={styles.saveText}>Save</span>
            <FontAwesomeIcon icon={faFloppyDisk} className={styles.saveIcon} />
          </div>
        </div>
      </div>
      <h1
        id="blogId"
        className={styles.editBlogId}
        contentEditable
        suppressContentEditableWarning={true}
        onInput={(e) => {
          setBlog({ ...blog, blogId: e.target.textContent });
        }}
        data-placeholder="blog-id"
      >
        {defaultValueBlogId.current}
      </h1>
      <BlogEditor blog={blog} setBlog={setBlog} />
    </div>
  );
};

export default CreateBlog;
