import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";

import BlogListElement from "./BlogListElement/BlogListElement";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import styles from "./Blogs.module.scss";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const link = process.env.REACT_APP_API_ENDPOINT + "/blogs";
      const response = await Axios.get(link);
      setBlogs(response.data);
      const id = setTimeout(() => {
        setIsLoading(false);
        clearTimeout(id);
      }, 169);
    })();
  }, []);

  if (isLoading) return <span className={styles.temporaryLoader} />;

  return (
    <div className={styles.blogsContainer}>
      <h1>Blogs</h1>
      <div className={styles.blogsListContainer}>
        <Link to="/create-blog" style={{ alignSelf: "center" }}>
          <div className={styles.createBlogButton}>
            <FontAwesomeIcon icon={faPlus} className={styles.createBlogIcon} />
          </div>
        </Link>
        {blogs.map((blog, i) => (
          <BlogListElement
            key={i}
            blog={blog}
            blogs={blogs}
            setBlogs={setBlogs}
          />
        ))}
      </div>
    </div>
  );
};

export default Blogs;
