import React from "react";

import { ProjectShowcase } from "../../components";

import styles from "./Experience.module.scss";

const Experience = () => {
  return (
    <div className={styles.experienceContainer}>
      <h1>Experinece</h1>
      <ProjectShowcase />
    </div>
  );
};

export default Experience;
