import React from "react";

import styles from "./Dashboard.module.scss";

const Dashboard = () => {
  return (
    <div className={styles.dashboardContainer}>
      <h1>Dashboard</h1>
    </div>
  );
};

export default Dashboard;
