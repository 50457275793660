import React, { useRef, useState } from "react";

import { handlePaste } from "content-editable-html-stripper";
import { useDetectClickOutside } from "react-detect-click-outside";

import styles from "./BlogForm.module.scss";

const BlogForm = ({ blog, setBlog }) => {
  const defaultValue = useRef(blog.markdown);
  const [tagDefaultValue, setTagDefaultValue] = useState(blog.tags || []);
  const detectClickOutsideRef = useDetectClickOutside({
    onTriggered: () =>
      setBlog({
        ...blog,
        tags:
          blog.tags && blog.tags.filter((tag) => tag !== "Tag" && tag !== ""),
      }),
  });

  return (
    <div className={styles.blogFormContainer}>
      <input
        type="text"
        className={styles.title}
        placeholder="Title"
        value={blog.title || ""}
        onChange={(e) =>
          setBlog({
            ...blog,
            title: e.target.value,
          })
        }
      />
      <input
        type="text"
        className={styles.subtitle}
        placeholder="Subtitle"
        value={blog.subtitle || ""}
        onChange={(e) =>
          setBlog({
            ...blog,
            subtitle: e.target.value,
          })
        }
      />
      <input
        type="text"
        className={styles.thumbnail}
        placeholder="Thumbnail"
        value={blog.thumbnail || ""}
        onChange={(e) =>
          setBlog({
            ...blog,
            thumbnail: e.target.value,
          })
        }
      />
      <div
        id="markdown"
        onPaste={handlePaste}
        className={styles.markdown}
        contentEditable
        data-placeholder={`A paragraph with *emphasis* and **strong importance**.

> A block quote with ~strikethrough~ and a URL: https://reactjs.org.

* Lists
* [ ] todo
* [x] done

A table:

| a | b |
| - | - |
`}
        onInput={(e) =>
          setBlog({
            ...blog,
            markdown: e.target.textContent,
          })
        }
        suppressContentEditableWarning={true}
      >
        {defaultValue.current || ""}
      </div>
      <div className={styles.tagContainer} ref={detectClickOutsideRef}>
        {blog.tags?.map((tag, i) => (
          <span
            suppressContentEditableWarning={true}
            contentEditable
            key={i}
            className={`${styles.tag} tag`}
            style={tag === "Tag" ? { color: "red" } : {}}
            onInput={(e) => {
              setBlog({
                ...blog,
                tags: blog.tags
                  ? [
                      ...blog.tags.slice(0, i),
                      e.target.textContent,
                      ...blog.tags.slice(i + 1),
                    ]
                  : [],
              });
            }}
          >
            {tagDefaultValue[i]}
          </span>
        ))}
        <span
          className={styles.addTag}
          onClick={async () => {
            await setTagDefaultValue(blog.tags ? [...blog.tags, ""] : [""]);
            await setBlog({
              ...blog,
              tags: blog.tags ? [...blog.tags, ""] : [""],
            });
            await [...document.getElementsByClassName("tag")].pop().focus();
          }}
        >
          +
        </span>
      </div>
    </div>
  );
};

export default BlogForm;
