import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Store } from "react-notifications-component";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";

import { BlogEditor } from "../../components";

import styles from "./EditBlog.module.scss";

const EditBlog = () => {
  const { blogId } = useParams();
  const [blog, setBlog] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [cursor, setCursor] = useState("default");
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const link = process.env.REACT_APP_API_ENDPOINT + `/blogs/${blogId}`;
      const response = await Axios.get(link);
      if (!response.data) return navigate("/404", { replace: true });
      setBlog(response.data);
      const id = setTimeout(() => {
        setIsLoading(false);
        clearTimeout(id);
      }, 169);
    })();
  }, [blogId, navigate]);

  const handleBack = () => {
    if (
      window.confirm(
        "Are you sure you want to leave? (Unsaved changes will be lost)"
      )
    ) {
      window.history.back();
    }
  };

  const handleSave = async () => {
    try {
      setCursor("wait");
      const link = process.env.REACT_APP_API_ENDPOINT + `/blogs/${blog.blogId}`;
      const config = {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      };
      const response = await Axios.put(link, blog, config);
      if (response.status === 200) {
        Store.addNotification({
          title: "Success",
          message: "Blog edits were saved successfully!",
          type: "success",
          insert: "top",
          container: "top-left",
          animationIn: ["animate__animated animate__fadeIn"],
          animationOut: ["animate__animated animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
        return;
      }
    } catch (error) {
      Store.addNotification({
        title: "Failed",
        message: "Blog edits failed to save! (Network Error)",
        type: "danger",
        insert: "top",
        container: "top-left",
        animationIn: ["animate__animated animate__fadeIn"],
        animationOut: ["animate__animated animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    } finally {
      setCursor("default");
    }
  };

  function useKey(key, cb) {
    const callback = useRef(cb);

    useEffect(() => {
      callback.current = cb;
    });

    useEffect(() => {
      function handle(event) {
        if (event.code === key) {
          event.preventDefault();
          callback.current(event);
        } else if (key === "ctrls" && event.key === "s" && event.ctrlKey) {
          event.preventDefault();
          callback.current(event);
        } else if (key === "ctrls" && event.key === "s" && event.metaKey) {
          event.preventDefault();
          callback.current(event);
        }
      }

      document.addEventListener("keydown", handle);
      return () => document.removeEventListener("keydown", handle);
    }, [key]);
  }

  useKey("ctrls", handleSave);

  return (
    <div className={styles.editBlogContainer} style={{ cursor: cursor }}>
      <div className={styles.editBlogMenu}>
        <div className={styles.backContainer} onClick={handleBack}>
          <FontAwesomeIcon icon={faArrowLeft} className={styles.backIcon} />
          <span className={styles.backText}>Back to Blogs</span>
        </div>
        <h1 style={{ userSelect: "none" }}>&nbsp;</h1>
        <div className={styles.saveContainer} onClick={handleSave}>
          <span className={styles.saveText}>Save</span>
          <FontAwesomeIcon icon={faFloppyDisk} className={styles.saveIcon} />
        </div>
      </div>
      <h1 className={styles.editBlogId}>{blog.blogId}</h1>
      <BlogEditor blog={blog} setBlog={setBlog} isLoading={isLoading} />
    </div>
  );
};

export default EditBlog;
